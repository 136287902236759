var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex flex-wrap"},[_c('TMessage',{staticClass:"my-auto",attrs:{"content":_vm.supplier.id,"bold":"","noTranslate":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('TMessage',{staticClass:"my-auto",attrs:{"content":_vm.supplier.name,"bold":"","noTranslate":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}})],1),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"my-2",attrs:{"xl":"4","lg":"6","md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Supplier","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cilStore"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.supplier.name,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.purchase_suppliers.detail.update', {
              name: $event,
            })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"xl":"4","lg":"6","md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Email","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cilMail"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.supplier.email,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.purchase_suppliers.detail.update', {
              email: $event,
            })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"xl":"4","lg":"6","md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Address","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cilMap"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.supplier.address,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.purchase_suppliers.detail.update', {
              address: $event,
            })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"xl":"4","lg":"6","md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Link","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cilLink"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.supplier.link,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.purchase_suppliers.detail.update', {
              link: $event,
            })}}})],1),_c('CCol',{staticClass:"my-2",attrs:{"xl":"4","lg":"6","md":"6","sm":"12"}},[_c('TMessage',{attrs:{"content":"Note","color":"primary","bold":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cilNote"}})]},proxy:true}])}),_c('TMessageText',{staticClass:"mt-2",attrs:{"value":_vm.supplier.note,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.purchase_suppliers.detail.update', {
              note: $event,
            })}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }